import getData from "@/__main__/get-data.mjs";
import ValorantGuides from "@/data-models/valorant-guides.mjs";
import { getGuides, getGuidesHeadshots } from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import { getParamsStr } from "@/game-val/map-utils.mjs";

export default async function fetchData([map], searchParams) {
  await Promise.all([
    fetchConstants(),
    getData(
      getGuides(getParamsStr(searchParams, map)),
      ValorantGuides,
      ["val", "guides", btoa(getParamsStr(searchParams, map))],
      {
        shouldFetchIfPathExists: false,
      },
    ),
    getData(
      getGuidesHeadshots(),
      ValorantGuides,
      ["val", "guides", "headshots"],
      {
        shouldFetchIfPathExists: false,
      },
    ),
  ]);
}
